
import './Landing.css';

function Landing() {
  return (
    <div className="Landing">
        <h1> Hi! 👋 </h1>
        <h2>                    
          I'm Satish Surath.
        </h2>
        <hr />
    </div>
  );
}

export default Landing;

