

import './Projects.css';
import { BsTwitter } from "react-icons/bs";
import { RiStackshareFill } from "react-icons/ri";
import { BsInstagram } from "react-icons/bs";
import logosummarize4me from './Summarize4Me FavIcon-1.png';
import { BsGithub } from "react-icons/bs";
import { FaExternalLinkAlt } from "react-icons/fa";
import { BsLinkedin } from "react-icons/bs";

function Projects() {
  return (
    <div className="Projects">
      {
            
<div>
        <h2>
        Donate
        </h2>
        <p>
        Support My Ride to Conquer Cancer 2025: <br />
      Your donation empowers us to fight cancer head-on.
      </p>
<ul id="menu-social-links-menu" className="social-links-menu">
    
<li id="menu-item-23" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-23">
    <a href="https://u.sati.sh/ride" target="_blank" rel="noreferrer">
    <img src="https://s3.ca-central-1.amazonaws.com/clientcustomfiles/Org_39/EventLocation_33117/Participants/p_9625145/EmailBadge/FundraisingProgress_1.png?nocache=1719756409212" width="300px" height="auto"/> 
    </a>
    
  </li>  
  </ul>
  </div>
    /*
                <a href="https://summarizeme.io" target="_blank" rel="noreferrer"> 
            <h2>
              SummarizeMe.io
            </h2>
          </a>      
      <p>
      AI-powered text summarization made simple. <br />
      Condense articles, web pages, and PDFs effortlessly.
      </p>
        <ul id="menu-social-links-menu" className="social-links-menu">
    
          <li id="menu-item-23" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-23">
              <a href="https://summarizeme.io/" target="_blank" rel="noreferrer">
              <img src={logosummarize4me} alt="summarizeme Logo" className="SummarizeMeLogo"/></a>
              
            </li>  
            <li id="menu-item-23" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-23">
              <a href="https://github.com/satishsurath/summarizeme.io" target="_blank" rel="noreferrer">
                  <BsGithub />
                </a>
            </li>
            <li id="menu-item-23" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-23">
            <a href="https://twitter.com/summarizemeio" target="_blank" rel="noreferrer">
                <BsTwitter />
              </a>
          </li>   
          <li id="menu-item-23" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-23">
                <a href="https://www.linkedin.com/company/summarizeme/" target="_blank" rel="noreferrer">
                  <BsLinkedin />
                </a>
            </li>                                                                                                                            
        </ul>
          <hr />
          <a href="https://alldayta.xyz" target="_blank" rel="noreferrer"> 
            <h2>
              All Day TA
            </h2>
          </a>      
      <p>
      ChatGPT based Virtual Teaching Assistant! <br />
      Upload Course material to create TA chatbots.
      </p>
        <ul id="menu-social-links-menu" className="social-links-menu">
    
          <li id="menu-item-23" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-23">
              <a href="https://alldayta.xyz" target="_blank" rel="noreferrer">
              <FaExternalLinkAlt />
              </a>
              
            </li>  
            <li id="menu-item-23" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-23">
              <a href="https://github.com/satishsurath/alldayta.xyz" target="_blank" rel="noreferrer">
                  <BsGithub />
                </a>
            </li>                                                                                                                        
        </ul>
          <hr />

          <h2>
           Work Life <b>Travel</b> Integration
          </h2>
          <p>
          <a href="https://blog.sati.sh/blog/2020/12/22/work-life-travel-integration/" target="_blank" rel="noreferrer">
          Read more: #WorkLifeTravelIntegration
          </a>
          </p>
          */
       }   
    </div>
    
  );
}

export default Projects;

