
import './Meet.css';
import { BsFillCalendarWeekFill } from "react-icons/bs";

function Meet() {
  return (
    <div className="Meet">
        <h2> Meet </h2>
        <p>
          Grab some time with me  
        </p>
        <div className="wrap">
          <ul>
            <li className="meet-li">
                <a href="https://u.sati.sh/meet" target="_blank" rel="noreferrer">
                  <BsFillCalendarWeekFill />
                </a>
            </li>                                                                                                                 
          </ul>
        </div>
        <hr />
    </div>
  );
}

export default Meet;
